import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useState } from "react";
import { questions } from "./questions_database";

function App() {
  // 1. Propriétés
  const [showFinalResults, setShowFinalResults] = useState(false);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  // 2. Helper fonctions
  const optionClicked = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    } else {
      alert("Arf... tu feras mieux à la prochaine question !");
    }

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowFinalResults(true);
    }
  };

  const resetQuiz = () => {
    setCurrentQuestion(0);
    setShowFinalResults(false);
    setScore(0);
  };

  return (
    <div className="App">
      {/*  1. Header avec le titre */}
      <Header />

      {/*  2. Affichage du score */}
      <h2
        style={{
          fontFamily: "Righteous, cursive",
          color: "#1c536a",
        }}
      >
        Score actuel <br />
        <span style={{ color: "#008fcc" }}>
          {score}/{questions.length}
        </span>
      </h2>

      {showFinalResults ? (
        // 4. Résultat final
        <div className="final-results">
          <h1>Résultat final</h1>
          <h2>
            {score} {score > 1 ? "correctes" : "correcte"} sur{" "}
            {questions.length} ({Math.round((score / questions.length) * 100)}
            %)
          </h2>
          <button onClick={resetQuiz}>Recommencer</button>
        </div>
      ) : (
        // 3. Question Card
        <div className="card">
          <h3>
            Question {currentQuestion + 1} sur {questions.length}
          </h3>
          <h5>Module {questions[currentQuestion].module}</h5>
          <h2 style={{ fontWeight: "bold" }}>
            {questions[currentQuestion].nom}
          </h2>
          <ul>
            {questions[currentQuestion].options.map((option) => {
              return (
                <li
                  onClick={() => optionClicked(option.isCorrect)}
                  key={option.answerId}
                >
                  {option.text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default App;
