import React from "react";
import "../styles/Header.css";

const Header = ({ score, nbrOfQuestions, handleChangeScore }) => {
  return (
    <div className="header">
      <h1>Quiz Économie</h1>
    </div>
  );
};

export default Header;
