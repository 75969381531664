export const questions = [
  {
    id: "01",
    nom: "Quel est le rôle de l'État sur la politique de l'emploi ?",
    module: "Économie",
    options: [
      { answerId: 1, text: "Aucun", isCorrect: false },
      { answerId: 2, text: "Il a le premier rôle", isCorrect: false },
      { answerId: 3, text: "Il est figurant", isCorrect: false },
      { answerId: 4, text: "Il régule le marché du travail", isCorrect: true },
    ],
    valuePoint: 1,
  },
  {
    id: "02",
    nom: "Indiquez ci-dessous ce qui n'est PAS un facteur de production",
    module: "Économie",
    options: [
      { answerId: 1, text: "La terre", isCorrect: false },
      { answerId: 2, text: "Le capital", isCorrect: false },
      { answerId: 3, text: "Le travail", isCorrect: false },
      { answerId: 4, text: "Un prêt bancaire", isCorrect: true },
    ],
    valuePoint: 1,
  },
  {
    id: "03",
    nom: "En économie, le problème central est",
    module: "Économie",
    options: [
      { answerId: 1, text: "La production", isCorrect: false },
      { answerId: 2, text: "La monnaie", isCorrect: false },
      { answerId: 3, text: "L'allocation des ressources", isCorrect: false },
      { answerId: 4, text: "La rareté", isCorrect: true },
      { answerId: 4, text: "La consommation", isCorrect: false },
    ],
    valuePoint: 1,
  },
  {
    id: "04",
    nom: "La microéconomie ne s'occupe pas du comportement...",
    module: "Économie",
    options: [
      { answerId: 1, text: "de la demande agrégée", isCorrect: true },
      { answerId: 2, text: "des industries", isCorrect: false },
      { answerId: 3, text: "des entreprises", isCorrect: false },
      { answerId: 4, text: "des consommateurs", isCorrect: false },
    ],
    valuePoint: 1,
  },
  {
    id: "05",
    nom: "L'inflation est...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "une baisse du niveau général de l'activité économique",
        isCorrect: false,
      },
      {
        answerId: 2,
        text: "une augmentation du niveau général de l'activité économique",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "une augmentation du niveau général des prix",
        isCorrect: true,
      },
      {
        answerId: 4,
        text: "une baisse du niveau général des prix",
        isCorrect: false,
      },
    ],
    valuePoint: 1,
  },
  {
    id: "06",
    nom: "Une récession est...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "une période durant laquelle le chômage baisse",
        isCorrect: false,
      },
      {
        answerId: 2,
        text: "une période durant laquelle les prix baissent",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "une période durant laquelle le produit agrégé diminue",
        isCorrect: true,
      },
      {
        answerId: 4,
        text: "une période durant laquelle les prix baissent de manière très rapide",
        isCorrect: false,
      },
    ],
    valuePoint: 1,
  },
  {
    id: "07",
    nom: "Dans une économie planifiée, toutes les décisions économiques sont prises par...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "l'État",
        isCorrect: true,
      },
      {
        answerId: 2,
        text: "les consommateurs",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "les électeurs",
        isCorrect: false,
      },
      {
        answerId: 4,
        text: "les travailleurs",
        isCorrect: false,
      },
    ],
    valuePoint: 1,
  },
  {
    id: "08",
    nom: "La monnaie est...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "la valeur de tous les billets et pièces en circulation à tout moment",
        isCorrect: false,
      },
      {
        answerId: 2,
        text: "la même chose que le revenu",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "tout ce qui est généralement accepté comme intermédiaire des échanges",
        isCorrect: true,
      },
      {
        answerId: 4,
        text: "l'ensemble des devises existant sur le marché des changes",
        isCorrect: false,
      },
    ],
    valuePoint: 1,
  },
  {
    id: "09",
    nom: "Une entreprise qui fixe différents prix à différents acheteurs pour un même produit pratique...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "une guerre des prix",
        isCorrect: false,
      },
      {
        answerId: 2,
        text: "les rabais sur les prix",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "la fixation des prix",
        isCorrect: false,
      },
      {
        answerId: 4,
        text: "la discrimination par les prix",
        isCorrect: true,
      },
    ],
    valuePoint: 1,
  },
  {
    id: "10",
    nom: "La « loi de la demande implique » que...",
    module: "Économie",
    options: [
      {
        answerId: 1,
        text: "lorsque les prix augmentent la demande baisse",
        isCorrect: false,
      },
      {
        answerId: 2,
        text: "lorsque le prix baisse la demande augmente",
        isCorrect: false,
      },
      {
        answerId: 3,
        text: "lorsque les prix baissent la quantité demandée augmente",
        isCorrect: true,
      },
      {
        answerId: 4,
        text: "lorsque les prix augmentent la quantité demandée augmente",
        isCorrect: false,
      },
    ],
    valuePoint: 1,
  },
];
